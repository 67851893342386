import React from "react"
import Content from "../components/Content"
import PromoData from "../components/PromoData"
import ContentContainer from "../components/ContentContainer"
import ContentBiggerText from "../components/ContentBiggerText"
import ContentSmallerText from "../components/ContentSmallerText"
import CouseTermsList from "../components/CouseTermsList"
import CourseTermsWrapper from "../components/CourseTermsWrapper"
import Team from "../components/Team"
import Layout from "../components/LayoutTest"
import CourseList from "../components/CourseList"
import SubHeader from "../components/SubHeader"
import Seo from '../components/Seo'

export default function Home() {
  return (
    <Layout includeBanner>
      <Seo title="Kursy finansowe, księgowości, kadry i płace Wrocław" />
      <div style={{maxWidth: '600px', margin: '0 auto'}}>
        <p><a href="https://www.helpdesk.com/tour/">Link to www.heldesk.com tour</a></p>
        <p><a href="https://www.livechat.com/pricing/">Link to www.liveChat.com pricing</a></p>
        <p><a href="https://livechat.com/customers/">Link to www.LiveChat.com customers</a></p>
        <p><a href="https://www.chatbot.com/features/">Link to www.chatbot.com features</a></p>
        <p><a href="https://chatbot.com/benefits/">Link to chatbot.com features</a></p>
        <p><a href="https://openwidget.com/widgets/faq-template">Link to openwidget.com faq template</a></p>
        <p><a href="https://www.openwidget.com/pricing">Link to www.openwidget.com pricing</a></p>
        <p><a href="https://www.knowledgebase.com/features">Link to www.knowledgebase.com kb</a></p>
        
      </div>
      <h2>utms</h2>
      <div style={{maxWidth: '600px', margin: '0 auto'}}>
        <p><a href="https://www.helpdesk.com/tour/?utm_campaign=test&utm_source=example.org">Link to www.heldesk.com tour</a></p>
        <p><a href="https://www.livechat.com/pricing/?utm_campaign=test&utm_source=example.org">Link to www.liveChat.com pricing</a></p>
        <p><a href="https://livechat.com/customers/?utm_campaign=test&utm_source=example.org">Link to www.LiveChat.com customers</a></p>
        <p><a href="https://www.chatbot.com/features/?utm_campaign=test&utm_source=example.org">Link to www.chatbot.com features</a></p>
        <p><a href="https://chatbot.com/benefits/?utm_campaign=test&utm_source=example.org">Link to chatbot.com features</a></p>
        <p><a href="https://openwidget.com/widgets/faq-template?utm_campaign=test&utm_source=example.org">Link to openwidget.com faq template</a></p>
        <p><a href="https://www.openwidget.com/pricing?utm_campaign=test&utm_source=example.org">Link to www.openwidget.com pricing</a></p>
        <p><a href="https://www.knowledgebase.com/features/?utm_campaign=test&utm_source=example.org">Link to www.knowledgebase.com kb</a></p>
      </div>
    </Layout>
  )
}
