import React from "react"
import tw, { styled, GlobalStyles } from "twin.macro"

import { initTestLiveChat } from '../livechat'
import Navigation from "../components/Navigation"
import HeroBanner from "../components/HeroBanner"
import Footer from "../components/Footer"
import CookiesBanner from '../components/CookiesBanner'

const HeroContainer = styled.div`
  ${tw`bg-gray-50 shadow`};
`

const NewWebsiteBanner = styled.div`
  ${tw`p-1 bg-blue-700 text-white text-center text-sm`}
`

const Layout = ({ includeBanner, children }) => {
  React.useEffect(() => {
    initTestLiveChat()
  }, [])
  return (
    <div>
      <GlobalStyles />
      <HeroContainer>
        <NewWebsiteBanner>
          Odświeżyliśmy naszą stronę internetową!
        </NewWebsiteBanner>
        <Navigation />
        {includeBanner && <HeroBanner />}
      </HeroContainer>
      {children}
      <Footer />
      <CookiesBanner />
    </div>
  )
}

export default Layout
